import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

const VideoSharing = () => {
    const [videoData, setVideoData] = useState(null);
    const { shareCode } = useParams();

    useEffect(() => {
        // Fetch video data from API
        axios.get(`https://d2dv9r108cst1.cloudfront.net/api/get-media-by-code/${shareCode}`)
            .then(response => {
                setVideoData(response.data.data);
            })
            .catch(error => {
                console.error('Error fetching video data:', error);
            });
    }, [shareCode]);

    if (!videoData) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <Helmet>
                <title>{videoData.title}</title>
                <meta name="description" content={videoData.description} />
                <meta name="keywords" content={videoData.keywords} />
                <meta property="og:title" content={videoData.title} />
                <meta property="og:description" content={videoData.description} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:image" content={`https://content-creator-reel-star-bucket.s3.ap-south-1.amazonaws.com/${videoData.mediaThumbnailFile}`} />
            </Helmet>
            <h1>{videoData.title}</h1>
            <div className="video-container">
                <video controls width="600">
                    <source src={`https://content-creator-reel-star-bucket.s3.ap-south-1.amazonaws.com/${videoData.mediaFile}`} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <p>{videoData.description}</p>
            </div>
        </div>
    );
};

export default VideoSharing;
